//require('./bootstrap');

var $ = require('jquery');

window.$ = window.jQuery = $;

window.bootstrap = require('bootstrap5/dist/js/bootstrap.min');

//reload captcha
$('#reload').click(function () {
    $.ajax({
        type: 'GET',
        url: 'reload-captcha',
        success:function (data){
            $('.captcha span').html(data.captcha);
        }
    });
});

//abrir y cerrar menu
const navResponsive = () =>{
    document.querySelector('.close-menu-icon').classList.toggle('open');
    document.querySelector('.open-menu-icon').classList.toggle('open');
    document.querySelector('nav ul').classList.toggle('open');
    document.querySelector('#main-container').classList.toggle('open');
    document.querySelector('.site-header').classList.toggle('open');
    document.querySelector('.logo img').classList.toggle('open');
}
document.querySelector('.button-nav').addEventListener('click',navResponsive);

//parallax imagen de la portada
window.onscroll = function () {
    let y = .15 * window.scrollY;
    const image = document.querySelector('.imagen-portada');
    image.style.objectPosition = 'center -' + y + 'px';
}

// imagen sección introducción parallax
let portadaParallax = document.querySelector('.fachada-home');

function scrollPortada(){
    let scrollTop = this.pageYOffset;
    if(portadaParallax == null){
        return;
    }else{
        portadaParallax.style.transform = 'translateY(' + scrollTop * 0.04 + 'px)';
    }
}

window.addEventListener('scroll', scrollPortada);
